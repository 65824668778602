import React from 'react'
import Haircal from './haircal'
import './hairTest.css'

const HairTest = () => {
  return (
    <div className='hairTest'>
      <div className='hairTest2'><Haircal /></div>
    </div>
  )
}

export default HairTest

import React, { useState } from "react";
import "./haircal.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AuthAxios from "../intreceptor/authAxios";
import { Modal, Spin } from "antd";

function Haircal() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [cost, setcost] = useState({
    from: 0,
    to: 0,
  });
  const [graft, setgraft] = useState({
    from: 0,
    to: 0,
  });
  const [spinning, setSpinning] = React.useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const notify = (x) => {
    if (x) {
      toast.success(
        "Thank you. Your hair transplant cost analysis report will be sent to your mobile number soon!",
        {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
      );
    } else {
      toast.error("Something went wrong. Please try again later!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const userId = localStorage.getItem("userId");

  const totalArea = data ? data.map((itm) => itm.area) : [];

  const updateGraft1 = () => {
    if (data.some((item) => item.area === 1)) {
      setData((prevData) => prevData.filter((item) => item.area !== 1));
      setcost((prev) => ({
        ...prev,
        from: cost.from - 20000,
        to: cost.to - 30000,
      }));
      setgraft((prev) => ({
        ...prev,
        from: graft.from - 1000,
        to: graft.to - 1500,
      }));
    } else {
      const newItem = {
        area: 1,
        cost: "20000 to 30000",
        graft: "1000 to 1500",
      };
      setData((prevData) => [...prevData, newItem]);
      setcost((prev) => ({
        ...prev,
        from: cost.from + 20000,
        to: cost.to + 30000,
      }));
      setgraft((prev) => ({
        ...prev,
        from: graft.from + 1000,
        to: graft.to + 1500,
      }));
    }
  };

  const updateGraft2 = () => {
    if (data.some((item) => item.area === 2)) {
      setData((prevData) => prevData.filter((item) => item.area !== 2));
      setcost((prev) => ({
        ...prev,
        from: cost.from - 15000,
        to: cost.to - 22500,
      }));
      setgraft((prev) => ({
        ...prev,
        from: graft.from - 1000,
        to: graft.to - 1500,
      }));
    } else {
      const newItem = {
        area: 2,
        cost: "15000 to 22500",
        graft: "1000 to 1500",
      };
      setData((prevData) => [...prevData, newItem]);
      setcost((prev) => ({
        ...prev,
        from: cost.from + 15000,
        to: cost.to + 22500,
      }));
      setgraft((prev) => ({
        ...prev,
        from: graft.from + 1000,
        to: graft.to + 1500,
      }));
    }
  };

  const updateGraft3 = () => {
    if (data.some((item) => item.area === 3)) {
      setData((prevData) => prevData.filter((item) => item.area !== 3));
      setcost((prev) => ({
        ...prev,
        from: cost.from - 15000,
        to: cost.to - 22500,
      }));
      setgraft((prev) => ({
        ...prev,
        from: graft.from - 1000,
        to: graft.to - 1500,
      }));
    } else {
      const newItem = {
        area: 3,
        cost: "15000 to 22500",
        graft: "1000 to 1500",
      };
      setData((prevData) => [...prevData, newItem]);
      setcost((prev) => ({
        ...prev,
        from: cost.from + 15000,
        to: cost.to + 22500,
      }));
      setgraft((prev) => ({
        ...prev,
        from: graft.from + 1000,
        to: graft.to + 1500,
      }));
    }
  };

  const updateGraft4 = () => {
    if (data.some((item) => item.area === 4)) {
      setData((prevData) => prevData.filter((item) => item.area !== 4));
      setcost((prev) => ({
        ...prev,
        from: cost.from - 15000,
        to: cost.to - 22500,
      }));
      setgraft((prev) => ({
        ...prev,
        from: graft.from - 1000,
        to: graft.to - 1500,
      }));
    } else {
      const newItem = {
        area: 4,
        cost: "15000 to 22500",
        graft: "1000 to 1500",
      };
      setData((prevData) => [...prevData, newItem]);
      setcost((prev) => ({
        ...prev,
        from: cost.from + 15000,
        to: cost.to + 22500,
      }));
      setgraft((prev) => ({
        ...prev,
        from: graft.from + 1000,
        to: graft.to + 1500,
      }));
    }
  };

  const updateGraft5 = () => {
    if (data.some((item) => item.area === 5)) {
      setData((prevData) => prevData.filter((item) => item.area !== 5));
      setcost((prev) => ({
        ...prev,
        from: cost.from - 15000,
        to: cost.to - 22500,
      }));
      setgraft((prev) => ({
        ...prev,
        from: graft.from - 1000,
        to: graft.to - 1500,
      }));
    } else {
      const newItem = {
        area: 5,
        cost: "15000 to 22500",
        graft: "1000 to 1500",
      };
      setData((prevData) => [...prevData, newItem]);
      setcost((prev) => ({
        ...prev,
        from: cost.from + 15000,
        to: cost.to + 22500,
      }));
      setgraft((prev) => ({
        ...prev,
        from: graft.from + 1000,
        to: graft.to + 1500,
      }));
    }
  };

  const updateGraft6 = () => {
    if (data.some((item) => item.area === 6)) {
      setData((prevData) => prevData.filter((item) => item.area !== 6));
      setcost((prev) => ({
        ...prev,
        from: cost.from - 15000,
        to: cost.to - 22500,
      }));
      setgraft((prev) => ({
        ...prev,
        from: graft.from - 1000,
        to: graft.to - 1500,
      }));
    } else {
      const newItem = {
        area: 6,
        cost: "15000 to 22500",
        graft: "1000 to 1500",
      };
      setData((prevData) => [...prevData, newItem]);
      setcost((prev) => ({
        ...prev,
        from: cost.from + 15000,
        to: cost.to + 22500,
      }));
      setgraft((prev) => ({
        ...prev,
        from: graft.from + 1000,
        to: graft.to + 1500,
      }));
    }
  };

  const updateGraft7 = () => {
    if (data.some((item) => item.area === 7)) {
      setData((prevData) => prevData.filter((item) => item.area !== 7));
      setcost((prev) => ({
        ...prev,
        from: cost.from - 15000,
        to: cost.to - 22500,
      }));
      setgraft((prev) => ({
        ...prev,
        from: graft.from - 1000,
        to: graft.to - 1500,
      }));
    } else {
      const newItem = {
        area: 7,
        cost: "15000 to 22500",
        graft: "1000 to 1500",
      };
      setData((prevData) => [...prevData, newItem]);
      setcost((prev) => ({
        ...prev,
        from: cost.from + 15000,
        to: cost.to + 22500,
      }));
      setgraft((prev) => ({
        ...prev,
        from: graft.from + 1000,
        to: graft.to + 1500,
      }));
    }
  };

  console.log({
    id: Number(userId),
    area: `${totalArea.join(",")}`,
    cost: `${cost.from} to ${cost.to}`,
    graft: `${graft.from} to ${graft.to}`,
  });

  const submit = () => {
    if (data.length > 0) {
      const apiDatas = {
        id: Number(userId),
        area: `${totalArea.join(",")}`,
        cost: `${cost.from} to ${cost.to}`,
        graft: `${graft.from} to ${graft.to}`,
      };

      const dataString = JSON.stringify(apiDatas);
      localStorage.setItem("oldData", dataString);

      setSpinning(true);
      AuthAxios.post("enquiry/update", apiDatas)
        .then((res) => {
          if (res.data?.success === 200) {
            setSpinning(false);
            // notify(true);
            setModalOpen(true);
            setTimeout(() => {
              navigate("/");
              localStorage.removeItem("userId");
            }, 4000);
          } else {
            setSpinning(false);
            notify(false);
          }
        })
        .catch((err) => {
          setSpinning(false);
          notify(false, err.message);
        });
    }
  };
  return (
    <>
      <div className="elementor-container elementor-column-gap-default mb-4">
        <div
          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b70b463"
          data-id="b70b463"
          data-element_type="column"
        >
          <div className="elementor-widget-wrap elementor-element-populated">
            <div
              className="elementor-element elementor-element-a547827 elementor-widget elementor-widget-shortcode"
              data-id="a547827"
              data-element_type="widget"
              data-widget_type="shortcode.default"
            >
              <div className="elementor-widget-container">
                <div
                  className="container hair-graft-block hair-graft-updated"
                  data-pg-ia-scene='{"l":[{"t":"this","a":"fadeIn"}],"d":"50%"}'
                  data-pg-ia-hide
                  style={{ textAlign: "center" }}
                >
                  <div className="row">
                    <center>
                      <div className="col-12">
                        <h3
                          className="head-underline mb-3"
                          style={{ color: "white" }}
                        >
                          Graft calculator
                        </h3>
                        <h6
                          className="head-underline mb-4"
                          style={{ color: "white" }}
                        >
                          Choose the areas you want to do hair transplant
                        </h6>
                        <br></br>
                      </div>
                    </center>
                    <div className="col-12 mt-2">
                      <div className="areas-picker">
                        <div className="areas-option"></div>
                        <div className="areas-option"></div>
                        <div className="areas-option"></div>
                        <div className="areas-option"></div>
                        <div className="areas-option"></div>
                        <div className="areas-option"></div>
                        <div className="areas-option areas-option-8"></div>
                      </div>
                      <div className="svg-wrapper">
                        {/* <p className="scroll-notice">Scroll left on mobile</p> */}
                        <svg
                          id="mpb"
                          className="layer img-fluid"
                          width="791px"
                          height="257px"
                          viewBox="0 0 791 257"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fill-rule="evenodd">
                            <g className="base">
                              <g transform="translate(0.000000, 2.676000)">
                                <path
                                  fill="#F0D8C5"
                                  d="M86.535,233.37c0,0-5.082-15.634-22.676-15.042c-17.594,0.592-36.302,13.748-41.326,1.798
												c-5.58-13.271,1.358-13.36,2.358-18.44c0.996-5.056-4.802-12.299-5.872-15.424c-1.21-3.523,1.296-7.313,1.296-7.313
												s0.012-14.32-0.132-19.742c0,0-16.404-2.276-19.148-6.242c-2.454-3.546-0.054-7.386,3.256-12.43
												c2.208-3.362,3.57-8.054,4.94-11.42c2.798-6.864,6.894-10.416,6.878-14.612c-0.014-3.714-5.844-6.572-6.02-10.27
												c-0.176-3.698,3.53-12.336,3.484-22.93c-0.046-10.592-0.292-34.144,7.256-45.336C28.375,24.774,66.973-6.44,120.549-1.09
												c53.574,5.35,71.352,25.412,75.646,72.114c4.146,45.086-32.064,84.844-32.386,101.84l5.432,23.422c0,0-7.258,24.569-79.52,55.204
												L86.535,233.37z"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M94.808,35.531c0,0-17.172,4.362-24,8.666c-16.154,10.182-21.232,24.992-0.784,45.866
												c14.544,14.848,9.238,27.908,8.176,35.06c-1.062,7.154-12.362,25.799-15.884,28.508c-3.522,2.711-25.744,11.719-25.744,11.719
												l16.464,17.705l54.638-11.789c0,0-8.162-40.07-4.878-54.586c3.284-14.518,12.898-45.422,12.898-45.422l-16.25-33.862
												C99.444,37.395,109.422,33.725,94.808,35.531"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M70.091,218.428c-8.632-1.538,24.188-15.865,32.338-23.714
												c8.15-7.847,17.24-14.446,16.374-21.464c-0.868-7.018-1.376-25.771,6.518-28.271c15.246-4.832,16.912-18.179,16.912-18.179
												s13.638,21.242,16.65,33.8c3.012,12.561,10.752,29.838,10.677,33.838c-0.077,4-4.125,10.184-4.639,10.784
												c-5.446,6.341-18.184,25.748-44.684,38.748c-26.502,13-29.828,10.784-30.516,7.521c0,0-1.89-14.477-4.854-17.019
												c-2.966-2.542-10.512-8.228-13.6-15.233"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M117.571,111.176c0,0,5.57-19.478,19.986-12.742c6.804,3.18,6.394,21.976,1.984,27.03
												c-8.404,9.633-2.64,7.758-9.446,13.213c-3.516,2.82-7.432,3.92-9.636,1.379c-2.202-2.543-2.706-3.875-0.994-7.723
												c1.444-3.246,7.48-12.072,2.552-11.342C119.919,121.302,118.309,117.48,117.571,111.176"
                                ></path>
                                <path
                                  fill="#F6E9DD"
                                  d="M36.728,41.435c0,0-12.282,17.854-13.064,29.37c-0.138,2.03,0.362,3.866,0.362,3.866
												l12.554-1.474l-13.134,4.412l-1.804,8.542l9.776-2.92l-10.676,6.998l-2.89,15.306l-2.762,2.256L11,106.609
												c0,0-2.11-1.24-0.678-8.154c1.564-7.542,2.794-14.75,2.378-22.298c-0.446-8.084,3.244-33.116,7.612-39.284
												C24.679,30.703,36.728,41.435,36.728,41.435"
                                ></path>
                                <g transform="translate(0.000000, 100.000000)">
                                  <g></g>
                                  <defs>
                                    <filter
                                      filterUnits="userSpaceOnUse"
                                      x="0"
                                      y="-1.546"
                                      width="46.104"
                                      height="61.293"
                                    >
                                      <feColorMatrix
                                        type="matrix"
                                        values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 1 0"
                                      ></feColorMatrix>
                                    </filter>
                                  </defs>
                                  <mask
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="-1.546"
                                    width="46.104"
                                    height="61.293"
                                  >
                                    <g filter="url(#Adobe_OpacityMaskFilter)">
                                      <polygon
                                        fill="#FFFFFF"
                                        points="0,-1.546 46.104,-1.546 46.104,59.747 0,59.747           "
                                      ></polygon>
                                    </g>
                                  </mask>
                                  <path
                                    mask="url(#mask-2)"
                                    fill="#D6BDAF"
                                    d="M46.104,4.166c0,0-4.518-6.25-10.778-5.674
													C29.066-0.932,15.998,8.52,15.998,8.52s2.286,2.96,5.018,5.326c2.732,2.366,2.644,7.754,1.044,12.026
													c-4.732,12.616-4.452,15.964-0.276,18.802c3.598,2.445-8.018,8.846-15.3,2.936c-4.178-3.392-6.406,1.639-6.406,1.639
													c-1.474,8.348,18.246,8.756,19.84,10.176c1.592,1.418,1.366-2.252,4.156-2.893c1.494-0.344,3.042-2.168,4.194-3.943
													c0.192-1.227,4.194-5.282-0.59-9.382c-5.514-4.728,3.92-19.3,4.886-21.364c0.964-2.064,12.42-4.11,12.42-4.11
													s-10.776-9.08-7.258-11.558S46.104,4.166,46.104,4.166"
                                  ></path>
                                </g>
                                <path
                                  fill="#000000"
                                  d="M29.606,150.377c-0.558,2.49-3.966,6.364-6.264,7.047c-2.346,0.697-0.708,2.226-2.526,2.229
												c-1.428,0.002-0.14-1.722,4.148-4.86c2.464-1.804,4.374-2.802,4.328-7.373C29.268,144.885,30.622,145.845,29.606,150.377"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M13.02,70.272c0,0,4.888-15.4,18.532-20.304c13.642-4.902,38.648-13.33,46.516,7.754
												c6.066,16.252,53.934-2.904,74.286,4.468c21.854,7.916,45.094,21.86,43.484,8.826c-5.75-46.546-22.072-66.764-75.646-72.114
												C66.616-6.448,26.338,26.736,18.79,37.928C18.79,37.928,9.634,49.724,13.02,70.272"
                                ></path>
                                <path
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="2"
                                  d="M13.02,70.272c0,0,4.888-15.4,18.532-20.304
												c13.642-4.902,38.648-13.33,46.516,7.754c6.066,16.252,53.934-2.904,74.286,4.468c21.854,7.916,45.094,21.86,43.484,8.826
												c-5.75-46.546-22.072-66.764-75.646-72.114C66.616-6.448,26.338,26.736,18.79,37.928C18.79,37.928,9.634,49.724,13.02,70.272z"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M13.196,69.453c0,0,4.548-14.478,18.19-19.382c13.644-4.902,36.464-12.914,45.826,8.124
												c9.306,20.908,6.314,18.61,8.298,32.65c1.984,14.042,13.592,17.51,13.996,22.52c0.406,5.008,1.234,12.636-1.97,19.558
												c-0.734,1.588,7.922,33.468,7.922,33.468s30.208-22.364,18.328-20.31c-5.788,1.002-20.996-5.17-14.204-17.41
												c3.29-5.926,1.434-9.682,1.878-16.406c0.018-0.276,3.902,0.572,3.97,0.186c0.954-5.272,6.844-19.958,20.878-17.346
												c19.152,3.568,6.674,30.388,5.266,33.862c-1.504,3.706,2.356,4.916,5.396,15.976c3.042,11.059,7.72,16.977,15.574,37.422
												c3.068,7.982,7.068,13.076,7.068,13.076l0.824-21.115c0.322-16.998,30.494-57.958,26.348-103.044
												c-4.294-46.702-23.182-66.926-76.758-72.276c-53.574-5.35-92.172,25.864-99.72,37.056C20.306,36.061,13.314,44.906,13.196,69.453"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M27.634,153.963c0,0-5.424,5.424,0.068,6.146c5.01,0.66,5.56-0.356,11.296,2.298
												c3.628,1.678,9.066,17.332,14.97,15.418c10.072-3.269,10.022-22.69,28.828-31.313c21.44-9.83,17.25-33.125,17.25-33.125
												l0.482,10.874c0,0,2.336,11.438,10.558,17.624c3.16,2.377,6.564,5.69,14.234,3.092c5.848-1.979,0.12,24.176-1.392,33.787
												c-4.48,28.455-42.668,40.094-53.44,42.748c0,0-40.902,13.771-48.246-0.15c-7.346-13.922,4.892-18.393-2.174-32.528
												c-2.616-5.235,3.18-8.981-0.102-9.438c-2.202-0.306-3.054-4.981-3.054-4.981l1.298-9.138l1.088-6.336
												C19.298,158.939,22.928,158.759,27.634,153.963"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M19.79,188.693c1.816,3.104,14.322-5.018,14.322-5.018s0.736-0.351-1.384,5.627
												c-2.12,5.979-8.068,9.484-9.278,13.576c-1.21,4.094,3.526,7.93,5.224,7.719c0.81-0.101,10.56-5.656,12.828-10.362
												c2.27-4.706,5.618-14.13,1.896-19.856c-3.72-5.724-23.432-0.984-23.432-0.984s-1.096,0.457-1.382,1.929
												C18.308,182.758,18.036,185.691,19.79,188.693"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M87.043,30.095c-17.39-9.184-36.248-6.724-46.38-5.404c-3.438,0.446-6.952,1.17-10.282,1.99
												c-5.734,4.854-9.482,9.074-11.014,11.346c-0.12,0.158-7.794,10.35-6.1,27.91c2.348-4.986,7.742-13.792,17.692-17.368
												c5.94-2.134,16.996-6.108,26.796-5.922c4.038,0.076,7.556,0.852,10.57,2.29c4.122-2.462,9.324-4.254,15.754-4.132l0.6,0.016
												c11.718,0.432,22.368,12.376,25.306,18.58c0.396,0.836,0.562,1.67,0.516,2.48c1.906-0.24,3.85-0.5,5.824-0.762
												c0.002-0.002,0.006-0.002,0.008-0.002c-0.026-0.122-0.054-0.254-0.082-0.378C113.149,55.029,100.769,37.345,87.043,30.095"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M87.977,28.327c11.072,5.846,21.826,17.862,26.724,25.74
												c-2.632-10.602-7.174-25.628-13.55-35.086C94.261,8.763,79.643,5.295,71.223,4.379C55.525,9.385,42.801,16.907,33.897,23.833
												c2.158-0.452,4.346-0.846,6.508-1.126C50.759,21.359,70.025,18.847,87.977,28.327"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M157.931,25.428c8.934,4.316,18.002,4.462,24.386,3.72
												c-9.202-15.066-23.816-23.304-46.798-27.53C138.009,6.743,145.13,19.243,157.931,25.428"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M78.751,56.914c1.49,3.994,5.844,5.922,13.704,6.072c4.168,0.078,10.916-0.248,15.98-0.854
												c0.128-0.592,0.056-1.21-0.258-1.874c-2.758-5.826-12.814-17.042-23.572-17.438l-0.564-0.016
												c-5.486-0.104-10.01,1.286-13.69,3.274C74.113,48.462,76.939,52.057,78.751,56.914"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M124.162-0.087c-1.416-0.168-2.854-0.328-4.324-0.474c-3.084-0.31-6.21-0.494-9.29-0.552
												c-12.054-0.23-24.73,1.73-34.636,4.456c8.512,1.572,20.668,5.282,26.896,14.522c8.112,12.03,13.198,32.17,15.358,42.19
												c0.152,0.286,0.29,0.548,0.402,0.764c7.65-1.014,15.428-1.962,22.248-1.832c0.13,0.002,0.242,0.014,0.37,0.018
												C142.298,38.875,127.45,6.745,124.162-0.087"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M186.135,36.428c-0.832-1.872-1.728-3.652-2.682-5.356c-2.286,0.31-4.942,0.518-7.85,0.464
												c-5.572-0.106-12.052-1.172-18.542-4.308c-14.766-7.134-22.112-21.838-23.97-26.05c-2.11-0.354-4.308-0.668-6.558-0.96
												c4.248,8.916,17.758,39.026,16.652,58.882c3.628,0.22,6.696,0.764,9.254,1.692c3.67,1.328,7.336,2.814,10.954,4.324
												C176.551,58.664,183.883,42.248,186.135,36.428"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M194.591,70.68c-1.542-12.486-3.884-22.902-7.346-31.59
												c-2.854,6.888-9.718,20.504-21.442,27.034c0.95,0.4,1.902,0.802,2.842,1.202c9.068,3.846,17.632,7.478,22.382,7.568
												c1.424,0.028,2.426-0.27,2.976-0.878C194.585,73.366,194.785,72.244,194.591,70.68"
                                ></path>
                                <path
                                  fill="#1D1D1B"
                                  d="M116.252,60.739c0.026,0.124,0.056,0.256,0.082,0.378c0.038-0.006,0.076-0.012,0.114-0.016
												C116.386,60.985,116.322,60.867,116.252,60.739"
                                ></path>
                              </g>
                              <g transform="translate(10.000000, 0.676000)">
                                <path
                                  fill="#000000"
                                  d="M41.071,118.475c-0.024-0.338-3.36-0.564-6.16-1.258c-0.3-0.074-3.628-1.782-5.012-2.092
												c-0.894-0.264-1.734-0.446-2.534-0.454c-2.464-0.326-4.976-0.398-7.486-0.464c-0.158-0.004-0.486-0.04-0.452,0.412
												c0.03,0.412,0.184,0.532,0.286,0.53c2.238-0.036,1.522,1.894,1.896,4.112c0.312,1.848,1.946,2.358,0.954,4.58
												c3.996-1.034,7.844-2.09,11.666-3.454C38.838,119.077,41.095,118.809,41.071,118.475"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M112.515,119.349c0,0,0.074-5.254,4.31-3.942c4.234,1.31,3.23,1.546,5.18,5.326
												c1.948,3.782,2.618,3.294,0.094,4.76c-2.522,1.466-5.508,0.172-5.81,3.922c-0.304,3.748-0.07,5.005-2.054,3.724
												c-1.984-1.277-1.478-4.105-0.43-7.35C115.188,121.501,112.343,120.485,112.515,119.349"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M117.802,107.927c0,0,8.228-8.268,10.632-1.49c2.404,6.776,1.856,16.398,0.476,19.676
												c-1.382,3.276,1.86-8.706-0.57-11.64C125.908,111.536,116.334,109.142,117.802,107.927"
                                ></path>
                                <path
                                  fill="bg-danger"
                                  d="M159.664,197.178c0,0-13.066-48.371-17.486-55.979c-4.42-7.606-10.604-10.232-10.604-10.232
												s-1.722,6.309-6.768,10.801c0,0,2.056,15.213,8.002,25.598c6.18,10.792,16.054,16.136,26.592,31.132"
                                ></path>
                                <path
                                  fill="#F6E9DD"
                                  d="M39.341,124.522c0,0-8.588,0.204-10.602,3.994s-3.466,5.594-1.834,7.83
												c1.632,2.237,5.904-5.78,9.984-8.546C40.969,125.036,44.179,122.708,39.341,124.522"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M6.874,176.292c0,0-0.024,2.124,0.144,2.983c0.168,0.849,0.93,1.648,3.54,2.496
												c6.398,2.078,14.208-1.918,18.442-1.787c0,0-7.598-3.215-11.908-4.17C13.554,175.029,8.704,173.765,6.874,176.292"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M14.894,160.225c-0.176,1.187-1.588,1.468-5.462,0.892c-3.876-0.576-9.406-1.718-9.23-2.904
												c0.176-1.186,4.12-1.758,7.994-1.18C12.072,157.609,15.07,159.039,14.894,160.225"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M36.104,106.166c-0.472,0.11-11.714-1.368-14.216,0.034
												c-3.444,1.932-12.714,7.318-18.392,5.638c0,0-1.05-0.468-1.692-1.412c-0.708-1.044-1.22-2.122-1.22-2.122
												c0.574-0.132,6.22-0.408,10.612-3.02c4.426-2.632,7.822-6.71,11.968-6.198c6.63,0.82,14.308,4.382,14.514,4.72
												C37.882,104.146,36.576,106.055,36.104,106.166"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M396.812,244.631c0,0,5.512-1.635,16.538-2.115c11.026-0.479,20.374-24.926,25.646-32.118
												c5.67-7.729,19.212-35.522,21.058-40.851c2.156-6.231,2.138-31.892,2.138-31.892s2.435-0.168,3.668-2.5
												c1.092-2.065,4.198-11.164,7.074-19.074c2.876-7.91,5.528-14.522,5.528-14.522s-0.994-3.196-2.672-5.832
												c-1.679-2.636-12.5,18.214-12.5,18.214c-0.393-10.784-2.889-34.592-8.299-61.634c-2.396-11.984-12.033-28.62-21.808-34.846
												C414.46,5.533,399.76,5.845,399.76,5.845l-12.09,0.949c0,0-17.532-1.198-36.256,10.73c-9.774,6.226-21.084,21.14-23.48,33.124
												c-5.41,27.042-2.596,47.57-1.66,56.418c0,0-9.334-12.872-11.786-10.934c-4.319,3.414,0.444,13.478,2.771,19.58
												c2.996,7.866,6.66,21.682,10.532,22.707c2.281,0.604-0.009,6.545-0.009,6.545s1.198,18.457,3.356,24.688
												c2.156,6.231,16.778,33.556,22.05,40.746c5.274,7.192,14.622,31.64,25.648,32.118c11.025,0.48,16.538,2.115,16.538,2.115H396.812z
												"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M323.706,127.759c0,0-2.742-1.526-3.148-4.218c-0.324-2.136-0.07-2.67,1.39-2.836
												c1.462-0.164,1,3.314,2.87,5.832S326.166,129.315,323.706,127.759"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M317.307,115.823c0,0-1.686-7.944-2.186-10.318c-0.5-2.376,0.5-2.126,0.5-2.126
												s8.124,13.126,8.624,16c0.5,2.876,2.952,6.39,3.075,5.764c0.169-0.838,0.286-10.89,0.551-13.264
												c0.25-2.25-5.876-11.624-6.376-12.624s-3-3.5-3-3.5s-2.624-1.626-4-0.376c-1.374,1.25-3.25,5-2.75,6.376
												C312.245,103.128,316.807,114.949,317.307,115.823"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M466.668,127.759c0,0,2.742-1.526,3.147-4.218c0.324-2.136,0.07-2.67-1.39-2.836
												c-1.462-0.164-1,3.314-2.87,5.832S464.208,129.315,466.668,127.759"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M473.066,115.823c0,0,1.687-7.944,2.187-10.318c0.5-2.376-0.5-2.126-0.5-2.126
												s-8.124,13.126-8.624,16c-0.5,2.876-2.467,6.642-2.59,6.016c-0.127-0.624-0.786-11.266-1.036-13.516s5.876-11.624,6.376-12.624
												s3-3.5,3-3.5s2.624-1.626,4-0.376c1.374,1.25,3.25,5,2.75,6.376C478.129,103.128,473.566,114.949,473.066,115.823"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M467.553,50.179c-6.232-16.298-24.891-50.77-70.468-50.334
												c-49.99,0.478-69.164,37.436-73.118,49.6c-6.232,19.176-1.187,52.506-1.187,52.506s5.227,27.141,5.706,30.496
												c0.479,3.355,8.196-23.784,14.908-24.744c4.032-0.576,14.172,2.494,26.272,5.996c8.153,2.36,18.71,3.398,25.979,2.634
												c8.8-0.926,16.326-1.162,23.592-2.332c1.63-0.264,1.971-0.282,4.048-0.742c3.862-0.852,9.206-2.128,12.402-2.264
												c9.086-0.39,25.152,16.842,26.112,19.24c0.958,2.396,8.147-31.64,8.147-31.64S473.784,66.477,467.553,50.179"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M345.337,160.717c0.344-0.207,4.876,3.418,21.646,8.771
												c6.464,2.063,15.827,1.387,15.92,2.406c0.092,1.021-1.002,2.74-1.969,2.98c-0.47,0.115-8.649,0.508-18.413-3.746
												c-9.765-4.252-17.038-7.955-17.435-9.338C344.963,161.354,344.993,160.922,345.337,160.717"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M379.587,209.672c0,0,7.979,8.442,15.372,8.088c6.462-0.31,13.63-4.162,15.548-7.302
												c1.918-3.142-9.59,5.019-15.548,5.444C389.043,216.324,378.867,206.532,379.587,209.672"
                                ></path>
                                <path
                                  fill="#F6E9DD"
                                  d="M353.377,145.672c0,0,21.29,8.442,41.017,8.088c17.244-0.31,36.372-4.162,41.488-7.302
												c5.115-3.142-25.587,5.019-41.488,5.444C378.609,152.324,351.459,142.532,353.377,145.672"
                                ></path>
                                <path
                                  fill="#F6E9DD"
                                  d="M366.551,141.34c0,0,13.609,3.924,26.222,3.76c11.021-0.145,23.25-1.937,26.521-3.396
												c3.271-1.458-16.357,2.334-26.521,2.532C382.681,144.431,365.324,139.879,366.551,141.34"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M349.866,169.304c-0.898,0.329,4.554,8.407,12.943,8.647
												c8.389,0.239,11.341-0.468,11.265-0.978c-0.074-0.511-11.479-0.353-14.895-1.521C355.766,174.286,350.764,168.974,349.866,169.304
												"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M442.092,160.717c-0.344-0.207-4.876,3.418-21.646,8.771
												c-6.464,2.063-15.828,1.387-15.92,2.406c-0.092,1.021,1.002,2.74,1.968,2.98c0.471,0.115,8.65,0.508,18.414-3.746
												c9.765-4.252,17.038-7.955,17.435-9.338C442.466,161.354,442.436,160.922,442.092,160.717"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M437.563,169.304c0.898,0.329-4.554,8.407-12.944,8.647
												c-8.388,0.239-11.34-0.468-11.264-0.978c0.074-0.511,11.479-0.353,14.895-1.521C431.662,174.286,436.665,168.974,437.563,169.304"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M366.53,112.791c0,0-18.762-5.778-21.428,8.222c-2.668,14-12.002,31.687-11.842,44.968
												c0,0-7.896-51.824-6.563-60.49c1.334-8.668,20.082-8.268,20.082-8.268L366.53,112.791z"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M422.059,113.535c0,0,20.268-6.522,22.934,7.478c2.668,14,12.002,31.684,11.842,44.966
												c0,0,7.896-51.822,6.563-60.49c-1.334-8.666-25.604-7.624-25.604-7.624L422.059,113.535z"
                                ></path>
                                <path
                                  fill="#bg-danger"
                                  d="M464.237,109.91c0,0-1,53.64-3.156,59.873c-2.157,6.231-14.172,33.55-17.72,41.731
												c-8.12,18.722-27.006,31.298-27.006,31.298s-10.313,4.76-20.888,4.76c-10.576,0-19.303-5.287-19.303-5.287
												s-14.126-7.362-27.64-30.463c-4.502-7.695-15.198-35.808-17.354-42.039c-2.158-6.232-3.356-24.688-3.356-24.688l-2.944-42.68
												c0,0,5.362,24.814,4.885,28.647c-0.48,3.836,3.294,41.362,8.088,46.637c2.188,2.408,8.52,10.195,13.812,17.605
												c6.3,8.818,10.322,29.092,12.928,30.135c4.792,1.918,19.912-3.24,30.282-3.591c8.366-0.284,25.342,5.257,30.977,4.332
												c4.785-0.786,3.777-19.728,13.174-31.288c7.229-8.896,15.626-17.932,16.67-26.27c2.396-19.174,6.656-52.312,6.656-52.312
												L464.237,109.91z"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M407.506,229.145c0.021,1.241-5.788,3.271-12.044,3.271c-5.974,0-11.281-2.187-11.302-3.363
												c-0.01-0.637,7.904,1.471,11.49,1.471C399.172,230.523,407.494,228.385,407.506,229.145"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M374.552,184.638c0,0,6.746,0.764,6.719,6.014c-0.026,5.076,0.172,5.473-1.843,7.37
												c-2.016,1.897-2.535-6.104-6.037-9.57C369.886,184.986,370.536,183.875,374.552,184.638"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M411.193,184.16c0,0-6.746,0.764-6.718,6.014c0.025,5.076-0.173,5.473,1.842,7.371
												c2.016,1.897,2.536-6.104,6.038-9.57C415.859,184.508,415.209,183.396,411.193,184.16"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M338.32,82.654c-5.689,0.686-10.598,1.384-14.644,2.014
												c0.174,9.464,0.874,16.64,0.904,16.796c0.033,0.174,3.075,15.464,4.708,24.272c0.004-0.012,0.292-0.692,0.296-0.704
												c4.424-10.708,8.17-18.766,13.195-19.482c2.929-0.412,9.057,1.986,15.971,4.598c2.466,0.932,5.092,1.818,7.779,2.642
												C360.986,105.208,349.759,90.54,338.32,82.654"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M449.557,81.853c-5.116-0.576-10.668-1.112-16.552-1.566
												c-5.294-0.432-8.24,3.092-11.946,7.54c-4.976,5.97-11.166,13.394-25.746,13.394h-0.176c-17.254-0.07-23.388-8.188-27.866-14.114
												c-2.968-3.928-5.086-6.75-9.306-6.414l-0.002-0.01c-6.048,0.488-11.622,1.046-16.66,1.622
												c14.096,10.528,24.928,26.644,28.364,31.394c8.023,2.248,16.403,3.798,23.218,3.798c6.479,0,16.182-1.642,26.354-3.496
												C424.755,106.417,436.942,89.987,449.557,81.853"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M331.132,78.682l0.724-1.864c2.324,0.904,4.664,2.214,6.99,3.76
												c14.908-1.774,34.882-3.442,58.132-3.596c21.016-0.146,40.269,1.382,55.442,3.18c1.117-0.6,2.233-1.146,3.346-1.582l0.73,1.864
												c-0.124,0.048-0.248,0.12-0.372,0.17c4.199,0.532,8.04,1.078,11.462,1.6c0.226-8.486-0.076-18.72-2.082-26.622
												c-13.438,2.42-39.316,6.27-69.347,6.596c-1.025,0.012-2.048,0.016-3.065,0.016c-29.082,0-54.87-4.396-67.396-6.95
												c-1.729,8.474-2.132,18.708-2.048,27.394c3.426-0.53,7.456-1.106,12.034-1.682C334.155,80.066,332.634,79.266,331.132,78.682"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M363.701,36.397c0-10.228,14.574-18.55,32.488-18.55c17.913,0,32.487,8.322,32.487,18.55
												c0,10.23-14.574,18.552-32.487,18.552C378.275,54.949,363.701,46.627,363.701,36.397 M396.135,60.189
												c29.776-0.324,55.426-4.124,68.826-6.532c-0.224-0.728-0.458-1.44-0.718-2.114c-6.36-16.536-15.046-27.632-24.286-35.034
												c-12.188-1.816-27.426-3.332-43.676-3.126c-16.727,0.196-32.021,1.71-44.179,3.406c-15.104,11.098-22.644,25.74-24.901,32.65
												c-0.4,1.222-0.754,2.516-1.074,3.862C338.999,55.923,365.991,60.507,396.135,60.189"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M368.866,85.901c4.462,5.904,10.016,13.254,26.28,13.32h0.168
												c13.644,0,19.256-6.732,24.208-12.676c2.18-2.614,4.292-5.148,6.92-6.716c-9.208-0.574-19.114-0.914-29.45-0.846
												c-12.096,0.08-23.288,0.574-33.351,1.276C365.584,81.605,367.168,83.653,368.866,85.901"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M436.677,14.034C423.325,4.676,409.261,2.482,399.64,2.482
												c-1.106,0-4.528,0.088-5.669,0.136c-15.859,0.664-28.418,5.37-38.197,11.66c11.523-1.48,25.436-2.718,40.483-2.894
												C411.063,11.204,425.043,12.44,436.677,14.034"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M422.192,113.479c10.75-1.862,17.08-5.448,24.584-5.448c2.941,0,3.696,0.932,5.315,1.468
												c5.908,1.958,9.305,5.726,10.32,10.91c1.63-6.358,3.004-14.448,4.668-22.286c0.145-1.29,0.73-6.806,0.992-13.888
												c-4.271-0.654-9.206-1.344-14.69-2.002C441.227,89.047,428.204,105.306,422.192,113.479"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M426.677,36.397c0-9.126-13.676-16.55-30.488-16.55c-16.81,0-30.488,7.424-30.488,16.55
												s13.679,16.552,30.488,16.552C413.001,52.949,426.677,45.523,426.677,36.397"
                                ></path>
                                <path
                                  fill="#1D1D1B"
                                  d="M456.123,80.614c-1.09,0.448-2.188,1-3.292,1.62c5.484,0.656,10.422,1.346,14.692,2.002
												c0.023-0.66,0.044-1.338,0.062-2.022C464.163,81.69,460.325,81.146,456.123,80.614"
                                ></path>
                                <path
                                  fill="#7B6D6C"
                                  d="M331.856,76.819l-0.725,1.864c1.502,0.584,3.024,1.382,4.551,2.282
												c1.025-0.128,2.082-0.258,3.163-0.386C336.521,79.033,334.181,77.722,331.856,76.819"
                                ></path>
                                <path
                                  fill="#1D1D1B"
                                  d="M455.766,78.58c-1.112,0.436-2.229,0.982-3.347,1.582c1.263,0.148,2.5,0.3,3.704,0.452
												c0.124-0.05,0.248-0.122,0.372-0.17L455.766,78.58z"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M422.869,6.756c0,0,10.347,5.662,16.984,18.35c8.012,15.318,13.42,50.716,9.752,80.684
												c-0.966,7.89,13.83,22.6,13.83,22.6s6.678-41.204,5.7-50.77c-0.977-9.566-1.918-33.804-16.984-50.17
												C436.34,10.269,422.869,6.756,422.869,6.756"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M368.045,6.756c0,0-10.346,5.662-16.983,18.35c-8.013,15.318-13.42,50.716-9.752,80.684
												c0.966,7.89-13.83,22.6-13.83,22.6s-6.679-41.204-5.7-50.77c0.976-9.566,1.918-33.804,16.984-50.17
												C354.575,10.269,368.045,6.756,368.045,6.756"
                                ></path>
                                <path
                                  fill="none"
                                  stroke="#000000"
                                  stroke-width="2"
                                  d="M333.411,109.439c0,0,4.72-6.798,18.318-1.7
												c13.596,5.1,32.798,9.632,43.81,9.632c11.519,0,22.274-0.97,40.601-7.744c8.686-3.21,17.371,0.756,17.371,0.756"
                                ></path>
                              </g>
                              <g transform="translate(632.000000, 2.676000)">
                                <path
                                  fill="#F0D8C5"
                                  d="M36.685,199.222c0.43-8.479-10.267-48.305-10.646-48.034
												c-0.382,0.272,42.104-86.954,42.104-86.954l32.756,28.77l43.54,30.504l-18.08,34.73c0,0-10.832,33.998-10.324,41.862
												s27.912,26.858,25.271,30.612c-2.08,2.951-46.893,10.899-61.732,10.899c-18.771,0-60.063-7.952-62.604-10.899
												C14.424,227.764,36.254,207.7,36.685,199.222"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M39.207,199.049c0.43-8.479-10.267-48.305-10.646-48.034
												c-0.382,0.272,42.104-86.954,42.104-86.954l32.756,28.77l43.54,30.504l-18.08,34.73c0,0-10.395,34.172-9.889,42.036
												c0.509,7.864,24.895,26.332,22.252,30.084c-2.079,2.952-36.525,16.534-58.432,16.534c-31.174,0-62.914-13.043-65.458-15.992
												C14.813,227.778,38.776,207.526,39.207,199.049"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M36.685,199.222c0.43-8.479-10.267-48.305-10.646-48.034
												c-0.382,0.272,42.104-86.954,42.104-86.954l32.756,28.77l43.54,30.504L55.723,144.49c0,0,10.1,63.643-20.841,81.006
												c-5.134,2.881-17.18,6.068-17.914,5.217C14.424,227.764,36.254,207.7,36.685,199.222"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M120.211,198.882c-0.43-8.478,11.643-47.964,12.022-47.692
												c0.38,0.271-42.104-86.956-42.104-86.956l-32.756,28.77L13.831,123.51l80.924,20.736c0,0,3,64.118,24.79,79.752
												c4.784,3.432,20.964,7.038,21.7,6.186C143.787,227.236,120.642,207.359,120.211,198.882"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M142.113,109.744c2.05-3.412,8.794-11.484,11.624-9.886c2.83,1.596,4.042,5.57,2.886,12.228
												c-1.157,6.656-3.773,13.22-7.464,19.361c-3.689,6.142-3.184,9.715-6.672,12.682c-3.486,2.966-9.074,4.382-9.074,4.382
												L142.113,109.744z"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M153.766,98.822c0,0,8.482,5.194,1.816,20.678c-11.33,26.32-13.908,25.976-15.862,26.494
												c-2.69,0.715,17.148-36.844,12.9-40.96c-5.578-5.404-4.532-7.844-4.532-7.844L153.766,98.822z"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M17.147,109.744c-2.05-3.412-8.794-11.484-11.624-9.886
												c-2.83,1.596-4.042,5.57-2.886,12.228c1.157,6.656,3.773,13.22,7.464,19.361c3.689,6.142,3.184,9.715,6.672,12.682
												c3.486,2.966,9.074,4.382,9.074,4.382L17.147,109.744z"
                                ></path>
                                <path
                                  fill="#D6BDAF"
                                  d="M5.495,98.822c0,0-8.482,5.194-1.816,20.678c11.33,26.32,13.908,25.976,15.862,26.494
												c2.69,0.715-17.148-36.844-12.9-40.96c5.578-5.404,4.532-7.844,4.532-7.844L5.495,98.822z"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M79.134,19.506c-16.812,0-30.487,7.424-30.487,16.55c0,9.128,13.676,16.552,30.487,16.552
												c16.811,0,30.488-7.424,30.488-16.552C109.622,26.929,95.944,19.506,79.134,19.506"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M141.127,30.48c-2.313-5.088-5.178-9.806-8.778-13.468
												c-9.624-9.79-24.636-19.298-51.844-18.63c-27.21,0.668-42.848,10.364-52.308,19.1c-3.185,2.94-5.727,6.378-7.805,10.056
												C34.217,15.92,55.404,8.466,79.135,8.466C104.811,8.466,127.515,17.192,141.127,30.48"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M79.134,54.607c-17.914,0-32.487-8.322-32.487-18.55c0-10.23,14.573-18.552,32.487-18.552
												s32.488,8.322,32.488,18.552C111.622,46.285,97.048,54.607,79.134,54.607 M79.134,10.467c-25.546,0-48.06,8.758-61.198,22
												c-2.863,6.468-4.621,13.302-6.077,19.334c2.896,2.056,6.189,3.984,9.909,5.732l0.116-0.036c0.01,0.036,0.028,0.074,0.038,0.11
												c14.526,6.782,34.8,11.006,57.212,11.006c22.416,0,42.692-4.226,57.218-11.01c0.007-0.02,0.015-0.038,0.019-0.058l0.067,0.016
												c4.263-1.996,8.021-4.212,11.192-6.608c-1.048-4.82-2.454-10.106-4.358-15.26C130.742,20.665,106.702,10.467,79.134,10.467"
                                ></path>
                                <path
                                  fill="#F0D8C5"
                                  d="M22.802,60.066c5.668,14.098,23.264,33.388,56.332,33.388
												c32.229,0,51.182-18.414,56.47-33.448c-14.708,6.564-34.592,10.606-56.47,10.606C57.325,70.612,37.495,66.594,22.802,60.066"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M79.134,52.607c-16.812,0-30.487-7.424-30.487-16.55c0-9.128,13.676-16.552,30.487-16.552
												c16.811,0,30.488,7.424,30.488,16.552C109.622,45.183,95.944,52.607,79.134,52.607 M79.134,17.505
												c-17.914,0-32.487,8.322-32.487,18.552c0,10.228,14.573,18.55,32.487,18.55s32.488-8.322,32.488-18.55
												C111.622,25.827,97.048,17.505,79.134,17.505"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M78.773,70.47c21.876,0,41.762-4.044,56.468-10.608
												c-5.286,15.036-24.238,33.448-56.468,33.448c-33.068,0-50.664-19.288-56.332-33.386C37.135,66.452,56.963,70.47,78.773,70.47
												M148.973,59.844c-0.34-2.108-0.762-4.494-1.279-7.04c-0.135-0.652-0.278-1.322-0.425-1.994c-3.172,2.396-6.93,4.612-11.191,6.608
												l-0.068-0.016c-0.004,0.02-0.012,0.038-0.018,0.058c-14.526,6.784-34.802,11.01-57.218,11.01
												c-22.412,0-42.687-4.224-57.213-11.006c-0.01-0.036-0.027-0.074-0.037-0.11l-0.116,0.036c-3.72-1.748-7.015-3.676-9.91-5.732
												c-0.16,0.662-0.318,1.31-0.47,1.95c-0.536,2.236-1.046,4.346-1.566,6.236c-3.196,11.584-0.4,43.83,0.398,45.76
												c0.8,1.93,16.578,49.804,21.361,64.636c1.63,5.051,17.168,9.854,30.17,12.766c13.33,2.984,22.881,2.717,35.652,0
												c25.928-5.516,28.208-9.184,29.428-14.016c5.181-20.51,20.305-50.382,21.639-60.548C148.381,106.37,150.893,71.744,148.973,59.844
												"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M79.134,10.467c27.568,0,51.608,10.198,64.138,25.226c-0.651-1.766-1.369-3.506-2.146-5.212
												c-13.61-13.288-36.313-22.014-61.992-22.014c-23.729,0-44.918,7.454-58.74,19.072c-0.905,1.602-1.716,3.254-2.458,4.928
												C31.074,19.224,53.588,10.467,79.134,10.467"
                                ></path>
                              </g>
                              <path
                                fill="none"
                                stroke="#000000"
                                stroke-width="2"
                                d="M781.334,62.662c-1.901-11.79-6.246-32.05-16.985-42.974
											c-9.624-9.79-24.636-19.298-51.845-18.63c-27.21,0.668-42.846,10.364-52.308,19.1c-12.572,11.61-15.18,30.92-18.376,42.504
											c-3.194,11.584-0.397,43.83,0.4,45.76c0.8,1.932,14.928,53.962,26.764,64.097c4.396,3.765,11.6,1.719,24.782-0.213
											c13.182-1.931,21.567-3.431,34.75-1.5c13.182,1.932,15.886,5.166,20.5,1.713c14.002-10.469,29.334-54.129,31.918-64.097
											C781.458,106.4,783.254,74.564,781.334,62.662z"
                              ></path>
                            </g>
                            <g className="scalp"></g>
                            <g
                              id="_2"
                              fill="#F0D8C5"
                              className="faded "
                              onClick={() => updateGraft2()}
                            >
                              <path
                                d="M87.043,32.771c-17.39-9.184-36.248-6.724-46.38-5.404
											c-3.438,0.446-6.952,1.17-10.282,1.99c-5.734,4.854-9.482,9.074-11.014,11.346c-0.12,0.158-7.794,10.35-6.1,27.91
											c2.348-4.986,7.742-13.792,17.692-17.368c5.94-2.134,16.996-6.108,26.796-5.922c4.038,0.076,7.556,0.852,10.57,2.29
											c4.122-2.462,9.324-4.254,15.754-4.132l0.6,0.016c11.718,0.432,22.368,12.376,25.306,18.58c0.396,0.836,0.562,1.67,0.516,2.48
											c1.906-0.24,3.85-0.5,5.824-0.762c0.002-0.002,0.006-0.002,0.008-0.002c-0.026-0.122-0.054-0.254-0.082-0.378
											C113.149,57.705,100.769,40.021,87.043,32.771"
                              ></path>
                              <path
                                d="M459.557,82.529c-5.116-0.576-10.668-1.112-16.552-1.566c-5.294-0.432-8.24,3.092-11.946,7.54
											c-4.976,5.97-11.166,13.394-25.746,13.394h-0.176c-17.254-0.07-23.388-8.188-27.866-14.114c-2.968-3.928-5.086-6.75-9.306-6.414
											l-0.002-0.01c-6.048,0.488-11.622,1.046-16.66,1.622c14.096,10.528,24.928,26.644,28.364,31.394
											c0.691,0.194,1.388,0.375,2.083,0.558c7.375,1.938,14.907,3.241,21.134,3.241c6.477,0,16.173-1.641,26.34-3.494
											c0.005-0.001,0.009-0.001,0.014-0.002C434.755,107.093,446.942,90.663,459.557,82.529z"
                              ></path>
                            </g>
                            <g
                              id="_3"
                              fill="#F0D8C5"
                              className="faded"
                              onClick={() => updateGraft3()}
                            >
                              <path
                                d="M87.977,31.003c11.072,5.846,21.826,17.862,26.724,25.74
											c-2.632-10.602-7.174-25.628-13.55-35.086C94.261,11.439,79.643,7.971,71.223,7.055c-15.698,5.006-28.422,12.528-37.326,19.454
											c2.158-0.452,4.346-0.846,6.508-1.126C50.759,24.035,70.025,21.523,87.977,31.003"
                              ></path>
                              <path
                                d="M406.992,79.659c-12.096,0.08-23.288,0.574-33.351,1.276c1.942,1.346,3.526,3.394,5.225,5.642
											c4.462,5.904,10.016,13.254,26.28,13.32h0.168c13.644,0,19.256-6.732,24.208-12.676c2.18-2.614,4.292-5.148,6.92-6.716
											C427.234,79.931,417.328,79.591,406.992,79.659z"
                              ></path>
                            </g>
                            <g
                              id="_1"
                              fill="#F0D8C5"
                              className="faded"
                              onClick={() => updateGraft1()}
                            >
                              <path
                                d="M78.751,59.59c1.49,3.994,5.844,5.922,13.704,6.072c4.168,0.078,10.916-0.248,15.98-0.854
											c0.128-0.592,0.056-1.21-0.258-1.874c-2.758-5.826-12.814-17.042-23.572-17.438l-0.564-0.016
											c-5.486-0.104-10.01,1.286-13.69,3.274C74.113,51.138,76.939,54.733,78.751,59.59"
                              ></path>
                              <path
                                d="M350.149,84.659c-0.061,7.348,0.294,14.729,1.16,21.807c0.008,0.067,0.006,0.138,0.012,0.207
											c0.476-0.211,0.959-0.375,1.458-0.446c2.929-0.412,9.057,1.986,15.971,4.598c2.457,0.929,5.073,1.812,7.75,2.633
											c0.009,0.003,0.019,0.006,0.029,0.009c0,0,0,0,0-0.001C371.281,106.288,360.941,92.771,350.149,84.659z"
                              ></path>
                              <path
                                d="M459.806,109.196c-0.218-0.969-0.303-1.891-0.2-2.731c0.872-7.123,1.226-14.552,1.159-21.945
											c-11.364,7.498-22.99,22.046-28.572,29.635c0.207-0.036,0.401-0.076,0.604-0.113c1.982-0.361,3.816-0.776,5.532-1.216
											c7.271-1.863,12.493-4.119,18.447-4.119C458.172,108.707,459.068,108.919,459.806,109.196z"
                              ></path>
                            </g>
                            <g
                              id="_6"
                              fill="#F0D8C5"
                              className="faded"
                              onClick={() => updateGraft6()}
                            >
                              <path
                                d="M157.931,28.104c8.934,4.316,18.002,4.462,24.386,3.72
											C173.115,16.758,158.5,8.52,135.519,4.293C138.009,9.419,145.13,21.919,157.931,28.104"
                              ></path>
                              <path
                                d="M436.677,37.073c0-9.126-13.676-16.55-30.488-16.55c-16.81,0-30.488,7.424-30.488,16.55
											s13.679,16.552,30.488,16.552C423.001,53.625,436.677,46.199,436.677,37.073z"
                              ></path>
                              <path
                                d="M689.586,50.43c5.521,2.997,13.143,4.853,21.548,4.853s16.027-1.856,21.548-4.853
											c5.521-2.997,8.94-7.134,8.94-11.698c0-3.423-1.924-6.606-5.214-9.248c-5.485-4.403-14.768-7.303-25.274-7.303
											s-19.79,2.9-25.273,7.303c-3.29,2.642-5.214,5.825-5.214,9.248C680.646,43.296,684.065,47.434,689.586,50.43z"
                              ></path>
                            </g>
                            <g
                              id="_4"
                              fill="#F0D8C5"
                              className="faded"
                              onClick={() => updateGraft4()}
                            >
                              <path
                                d="M124.162,2.589c-1.416-0.168-2.854-0.328-4.324-0.474c-3.084-0.31-6.21-0.494-9.29-0.552
											c-12.054-0.23-24.73,1.73-34.636,4.456c8.512,1.572,20.668,5.282,26.896,14.522c8.112,12.03,13.198,32.17,15.358,42.19
											c0.152,0.286,0.29,0.548,0.402,0.764c7.65-1.014,15.428-1.962,22.248-1.832c0.13,0.002,0.242,0.014,0.37,0.018
											C142.298,41.551,127.45,9.421,124.162,2.589"
                              ></path>
                              <path
                                d="M406.157,62.864c-1.025,0.012-2.048,0.016-3.065,0.016c-19.497,0-37.506-1.976-51.039-4.048
											c-0.993,7.073-1.64,14.618-1.843,22.264c14.755-1.713,34.212-3.288,56.768-3.438c20.232-0.141,38.819,1.271,53.717,2.981
											c-0.216-7.467-0.856-14.832-1.824-21.747C444.916,60.807,426.491,62.643,406.157,62.864z"
                              ></path>
                              <path
                                d="M711.135,11.142c11.682,0,22.734,1.822,32.609,5.042c6.894,2.248,13.2,5.184,18.736,8.677
											c3.964,2.501,7.551,5.272,10.646,8.294c-2.313-5.088-5.178-9.806-8.778-13.468c-9.624-9.79-24.636-19.298-51.844-18.63
											c-27.21,0.668-42.848,10.364-52.308,19.1c-3.185,2.94-5.727,6.378-7.805,10.056c4.018-3.376,8.674-6.388,13.817-8.98
											c1.141-0.575,2.305-1.128,3.493-1.66C681.6,14.256,695.831,11.142,711.135,11.142z"
                              ></path>
                            </g>
                            <g
                              id="_5"
                              fill="#F0D8C5"
                              className="faded"
                              onClick={() => updateGraft5()}
                            >
                              <path
                                d="M186.135,39.104c-0.832-1.872-1.728-3.652-2.682-5.356
											c-2.286,0.31-4.942,0.518-7.85,0.464c-5.572-0.106-12.052-1.172-18.542-4.308c-14.766-7.134-22.112-21.838-23.97-26.05
											c-2.11-0.354-4.308-0.668-6.558-0.96c4.248,8.916,17.758,39.026,16.652,58.882c3.628,0.22,6.696,0.764,9.254,1.692
											c3.67,1.328,7.336,2.814,10.954,4.324C176.551,61.34,183.883,44.924,186.135,39.104"
                              ></path>
                              <path
                                d="M406.135,60.865c20.241-0.22,38.571-2.047,52.447-3.951c-1.957-12.944-5.069-24.136-8.729-31.133
											c-1.98-3.786-4.291-6.941-6.559-9.513c-10.887-1.376-23.603-2.38-37.014-2.21c-14.485,0.169-27.892,1.328-39.122,2.737
											c-2.118,2.472-4.251,5.456-6.098,8.985c-3.654,6.986-6.762,18.153-8.719,31.071C366.489,59.018,385.577,61.082,406.135,60.865z
											M373.701,37.073c0-10.228,14.574-18.55,32.488-18.55c17.913,0,32.487,8.322,32.487,18.55c0,10.23-14.574,18.552-32.487,18.552
											C388.275,55.625,373.701,47.303,373.701,37.073z"
                              ></path>
                              <path
                                d="M653.547,60.1c0.075,0.036,0.145,0.074,0.221,0.109l0.116-0.036c0.01,0.036,0.028,0.074,0.038,0.11
											c1.728,0.807,3.543,1.574,5.428,2.306c13.907,5.334,31.85,8.557,51.424,8.557c22.416,0,42.691-4.226,57.218-11.01
											c0.006-0.02,0.014-0.038,0.018-0.058l0.068,0.016c4.262-1.996,8.02-4.212,11.191-6.608c0.025,0.117,0.049,0.23,0.074,0.346
											c0.092-0.069,0.195-0.134,0.287-0.204c-1.048-4.82-2.454-10.106-4.358-15.26c-12.529-15.028-36.569-25.226-64.138-25.226
											c-25.546,0-48.06,8.758-61.198,22c-2.863,6.468-4.621,13.302-6.077,19.334c2.812,1.996,6.006,3.868,9.594,5.576l0.071-0.022
											C653.529,60.053,653.538,60.076,653.547,60.1z M711.134,20.181c17.914,0,32.488,8.322,32.488,18.552
											c0,10.228-14.574,18.55-32.488,18.55s-32.487-8.322-32.487-18.55C678.646,28.503,693.22,20.181,711.134,20.181z"
                              ></path>
                            </g>
                            <g
                              id="_7"
                              fill="#F0D8C5"
                              className="faded"
                              onClick={() => updateGraft7()}
                            >
                              <path
                                d="M194.591,73.356c-1.542-12.486-3.884-22.902-7.346-31.59
											c-2.854,6.888-9.718,20.504-21.442,27.034c0.95,0.4,1.902,0.802,2.842,1.202c9.068,3.846,17.632,7.478,22.382,7.568
											c1.424,0.028,2.426-0.27,2.976-0.878C194.585,76.042,194.785,74.92,194.591,73.356"
                              ></path>
                              <path
                                d="M369.226,14.531c10.81-1.286,23.449-2.313,37.031-2.472c12.548-0.152,24.485,0.716,34.921,1.954
											c-4.487-4.487-8.309-6.582-8.309-6.582s0.069,0.019,0.192,0.055C424.307,4.132,416,3.158,409.64,3.158
											c-1.106,0-4.528,0.088-5.669,0.136c-11.99,0.502-22.091,3.316-30.533,7.377C372.17,11.7,370.729,12.983,369.226,14.531z"
                              ></path>
                              <path
                                d="M684.455,91.182c7.367,2.96,16.102,4.805,26.318,4.805c31.978,0,50.877-18.124,56.334-33.093
											c-11.464,5.034-26.003,8.542-42.146,9.835c-3.288,0.264-6.637,0.442-10.045,0.516c-1.255,0.027-2.515,0.043-3.783,0.043
											c-0.343,0-0.681-0.01-1.023-0.012l-0.158-0.139c-0.922-0.004-1.842-0.013-2.757-0.032l0.159,0.139
											c-3.409-0.073-6.757-0.252-10.045-0.515c-15.036-1.204-28.688-4.326-39.752-8.818c-0.936-0.375-1.852-0.761-2.75-1.156
											C658.699,72.425,668.202,84.53,684.455,91.182z"
                              ></path>
                            </g>
                            <g className="numbers">
                              <g fill="#000000">
                                <path
                                  fill="#000000"
                                  className="noev"
                                  d="M93.346,61.054h-2.185V52.82c-0.798,0.747-1.739,1.299-2.822,1.656v-1.982
												c0.57-0.187,1.189-0.54,1.858-1.062c0.668-0.521,1.127-1.128,1.376-1.822h1.772V61.054z"
                                ></path>
                                <path
                                  className="noev"
                                  fill="#000000"
                                  d="M45.598,39.358v2.029H37.94c0.083-0.767,0.332-1.494,0.747-2.181c0.415-0.687,1.233-1.598,2.457-2.732
												c0.984-0.918,1.588-1.54,1.812-1.866c0.3-0.451,0.451-0.896,0.451-1.337c0-0.487-0.131-0.861-0.393-1.124
												c-0.262-0.261-0.623-0.393-1.084-0.393c-0.456,0-0.819,0.138-1.088,0.412c-0.27,0.275-0.425,0.731-0.466,1.368l-2.177-0.217
												c0.13-1.203,0.537-2.065,1.221-2.589s1.54-0.785,2.565-0.785c1.125,0,2.008,0.303,2.651,0.909
												c0.643,0.606,0.964,1.36,0.964,2.263c0,0.513-0.092,1.001-0.276,1.465c-0.184,0.464-0.476,0.95-0.875,1.458
												c-0.265,0.337-0.741,0.821-1.431,1.454c-0.689,0.632-1.126,1.052-1.31,1.259c-0.184,0.208-0.333,0.41-0.447,0.606H45.598z"
                                ></path>
                                <path
                                  className="noev"
                                  fill="#000000"
                                  d="M74.096,18.379l2.115-0.256c0.067,0.539,0.249,0.95,0.544,1.235s0.653,0.428,1.073,0.428
												c0.451,0,0.831-0.171,1.139-0.513c0.308-0.343,0.462-0.804,0.462-1.384c0-0.55-0.147-0.985-0.443-1.307s-0.656-0.481-1.081-0.481
												c-0.28,0-0.614,0.054-1.003,0.163l0.241-1.78c0.591,0.016,1.042-0.113,1.353-0.385c0.311-0.272,0.466-0.634,0.466-1.085
												c0-0.384-0.114-0.689-0.342-0.917c-0.228-0.229-0.531-0.342-0.909-0.342c-0.374,0-0.692,0.129-0.957,0.389
												c-0.264,0.259-0.425,0.637-0.482,1.135l-2.014-0.342c0.14-0.689,0.351-1.24,0.634-1.652c0.282-0.412,0.676-0.736,1.182-0.972
												c0.505-0.236,1.072-0.354,1.699-0.354c1.073,0,1.933,0.342,2.581,1.025c0.534,0.561,0.801,1.192,0.801,1.897
												c0,1-0.547,1.799-1.641,2.395c0.653,0.14,1.175,0.453,1.567,0.94c0.391,0.487,0.587,1.075,0.587,1.765
												c0,1.001-0.366,1.854-1.096,2.558c-0.731,0.705-1.641,1.058-2.729,1.058c-1.031,0-1.886-0.297-2.565-0.891
												C74.598,20.114,74.205,19.338,74.096,18.379z"
                                ></path>
                                <path
                                  fill="#000000"
                                  className="noev"
                                  d="M125.356,33.936v-2.293h-4.665v-1.913l4.944-7.237h1.835v7.229h1.415v1.921h-1.415v2.293H125.356z
												M125.356,29.722v-3.895l-2.62,3.895H125.356z"
                                ></path>
                                <path
                                  className="noev"
                                  fill="#000000"
                                  d="M155.175,49.476l2.177-0.226c0.062,0.492,0.246,0.882,0.552,1.17c0.306,0.288,0.658,0.432,1.058,0.432
												c0.456,0,0.842-0.186,1.158-0.556c0.316-0.371,0.474-0.93,0.474-1.676c0-0.699-0.157-1.225-0.47-1.574s-0.722-0.524-1.225-0.524
												c-0.627,0-1.189,0.277-1.687,0.831l-1.772-0.256l1.12-5.932h5.776v2.044h-4.121l-0.342,1.937
												c0.487-0.244,0.984-0.366,1.493-0.366c0.969,0,1.791,0.353,2.464,1.058s1.011,1.62,1.011,2.744c0,0.938-0.272,1.775-0.816,2.512
												c-0.741,1.005-1.77,1.508-3.086,1.508c-1.052,0-1.91-0.282-2.573-0.848C155.701,51.188,155.305,50.429,155.175,49.476z"
                                ></path>
                                <path
                                  fill="#000000"
                                  d="M166.975,17.949l-2.115,0.233c-0.052-0.436-0.187-0.757-0.404-0.964s-0.5-0.311-0.847-0.311
												c-0.461,0-0.852,0.207-1.17,0.621c-0.318,0.415-0.52,1.278-0.603,2.589c0.544-0.643,1.221-0.964,2.029-0.964
												c0.912,0,1.693,0.348,2.344,1.042s0.976,1.591,0.976,2.689c0,1.167-0.342,2.103-1.026,2.807
												c-0.684,0.705-1.563,1.058-2.636,1.058c-1.15,0-2.096-0.447-2.837-1.341c-0.741-0.895-1.112-2.359-1.112-4.396
												c0-2.089,0.386-3.595,1.159-4.517c0.772-0.923,1.775-1.384,3.008-1.384c0.866,0,1.582,0.242,2.15,0.727
												C166.458,16.323,166.819,17.027,166.975,17.949z M162.023,22.715c0,0.71,0.163,1.259,0.49,1.645
												c0.326,0.386,0.7,0.579,1.119,0.579c0.404,0,0.741-0.158,1.011-0.474c0.27-0.316,0.404-0.835,0.404-1.556
												c0-0.741-0.145-1.283-0.435-1.628c-0.291-0.345-0.653-0.518-1.089-0.518c-0.419,0-0.774,0.165-1.065,0.494
												C162.168,21.587,162.023,22.072,162.023,22.715z"
                                ></path>
                                <path
                                  fill="#000000"
                                  className="noev"
                                  d="M183.205,63.618V61.59h7.471v1.586c-0.617,0.606-1.244,1.477-1.881,2.611
												c-0.638,1.136-1.124,2.342-1.458,3.619c-0.334,1.278-0.499,2.419-0.494,3.425h-2.107c0.036-1.575,0.361-3.183,0.976-4.82
												s1.435-3.102,2.46-4.393H183.205z"
                                ></path>
                              </g>
                              <g fill="#000000">
                                <path
                                  className="noev"
                                  fill="#000000"
                                  d="M455.398,105.242h-1.911v-7.204c-0.698,0.653-1.521,1.137-2.47,1.449v-1.734
												c0.499-0.164,1.041-0.473,1.627-0.929c0.584-0.456,0.986-0.987,1.203-1.596h1.551V105.242z"
                                ></path>
                                <path
                                  fill="#000000"
                                  className="noev"
                                  d="M408.129,112.413v1.775h-6.699c0.072-0.671,0.289-1.308,0.652-1.908s1.079-1.397,2.149-2.391
												c0.862-0.803,1.39-1.348,1.585-1.633c0.264-0.395,0.395-0.785,0.395-1.17c0-0.427-0.114-0.754-0.344-0.983
												c-0.229-0.229-0.545-0.344-0.949-0.344c-0.398,0-0.716,0.12-0.951,0.361c-0.236,0.24-0.373,0.639-0.408,1.197l-1.905-0.19
												c0.113-1.053,0.47-1.808,1.068-2.266s1.347-0.688,2.245-0.688c0.983,0,1.757,0.266,2.319,0.796
												c0.562,0.531,0.843,1.19,0.843,1.979c0,0.449-0.08,0.877-0.24,1.282c-0.162,0.406-0.417,0.831-0.766,1.276
												c-0.231,0.294-0.648,0.719-1.252,1.271c-0.604,0.554-0.985,0.921-1.146,1.103c-0.16,0.181-0.291,0.357-0.391,0.53H408.129z"
                                ></path>
                                <path
                                  className="noev"
                                  fill="#000000"
                                  d="M401.602,92.235l1.851-0.225c0.06,0.472,0.218,0.833,0.476,1.082c0.26,0.249,0.572,0.374,0.939,0.374
												c0.395,0,0.727-0.149,0.996-0.449c0.27-0.299,0.405-0.702,0.405-1.211c0-0.48-0.13-0.861-0.388-1.143
												c-0.259-0.281-0.574-0.422-0.945-0.422c-0.245,0-0.537,0.048-0.878,0.144l0.211-1.559c0.517,0.014,0.911-0.099,1.184-0.336
												c0.272-0.238,0.408-0.555,0.408-0.949c0-0.336-0.1-0.604-0.3-0.803c-0.199-0.199-0.465-0.3-0.795-0.3
												c-0.327,0-0.605,0.114-0.838,0.341c-0.23,0.227-0.371,0.558-0.421,0.993l-1.763-0.3c0.123-0.603,0.308-1.085,0.555-1.445
												c0.248-0.36,0.592-0.644,1.034-0.851c0.442-0.206,0.938-0.31,1.486-0.31c0.938,0,1.692,0.3,2.259,0.898
												c0.467,0.489,0.701,1.043,0.701,1.659c0,0.876-0.479,1.574-1.436,2.096c0.57,0.122,1.027,0.396,1.37,0.823
												c0.343,0.426,0.514,0.94,0.514,1.544c0,0.875-0.319,1.621-0.959,2.238c-0.64,0.616-1.436,0.925-2.388,0.925
												c-0.902,0-1.65-0.26-2.245-0.778C402.041,93.754,401.697,93.074,401.602,92.235z"
                                ></path>
                                <path
                                  className="noev"
                                  fill="#000000"
                                  d="M367.095,75.684v-2.007h-4.082v-1.673l4.327-6.334h1.605v6.327h1.238v1.68h-1.238v2.007H367.095z
												M367.095,71.997v-3.408l-2.292,3.408H367.095z"
                                ></path>
                                <path
                                  className="noev"
                                  fill="#000000"
                                  d="M448.479,75.688v-2.007h-4.082v-1.673l4.326-6.334h1.605v6.327h1.238v1.68h-1.238v2.007H448.479z
												M448.479,72.001v-3.408l-2.293,3.408H448.479z"
                                ></path>
                                <path
                                  className="noev"
                                  fill="#000000"
                                  d="M408.945,35.22l-1.85,0.204c-0.045-0.381-0.164-0.662-0.354-0.844c-0.191-0.182-0.438-0.272-0.742-0.272
												c-0.403,0-0.744,0.182-1.023,0.545c-0.279,0.362-0.455,1.117-0.527,2.265c0.477-0.562,1.068-0.843,1.775-0.843
												c0.799,0,1.482,0.304,2.051,0.911c0.569,0.607,0.854,1.393,0.854,2.354c0,1.021-0.299,1.839-0.897,2.456
												c-0.599,0.616-1.367,0.925-2.306,0.925c-1.008,0-1.835-0.391-2.483-1.173c-0.648-0.783-0.973-2.065-0.973-3.848
												c0-1.827,0.338-3.145,1.014-3.952c0.676-0.807,1.554-1.211,2.632-1.211c0.758,0,1.385,0.212,1.881,0.637
												C408.493,33.797,408.81,34.412,408.945,35.22z M404.613,39.39c0,0.621,0.143,1.101,0.428,1.438s0.612,0.507,0.979,0.507
												c0.354,0,0.648-0.138,0.884-0.415c0.236-0.276,0.354-0.729,0.354-1.36c0-0.648-0.127-1.123-0.381-1.425
												c-0.255-0.302-0.571-0.453-0.952-0.453c-0.367,0-0.678,0.145-0.932,0.433C404.739,38.402,404.613,38.827,404.613,39.39z"
                                ></path>
                                <path
                                  className="noev"
                                  fill="#000000"
                                  d="M442.955,49.343l1.904-0.197c0.055,0.431,0.216,0.772,0.483,1.023c0.268,0.252,0.575,0.378,0.925,0.378
												c0.399,0,0.737-0.162,1.014-0.486c0.277-0.324,0.415-0.813,0.415-1.466c0-0.612-0.138-1.071-0.411-1.378
												c-0.275-0.306-0.632-0.459-1.072-0.459c-0.549,0-1.041,0.242-1.476,0.728l-1.552-0.225l0.98-5.19h5.055v1.789h-3.605l-0.3,1.694
												c0.427-0.213,0.862-0.319,1.306-0.319c0.849,0,1.567,0.308,2.156,0.925c0.59,0.617,0.885,1.417,0.885,2.401
												c0,0.82-0.238,1.553-0.714,2.197c-0.649,0.88-1.549,1.319-2.7,1.319c-0.922,0-1.672-0.247-2.252-0.741
												C443.415,50.842,443.068,50.177,442.955,49.343z"
                                ></path>
                                <path
                                  className="noev"
                                  fill="#000000"
                                  d="M360.83,49.349l1.905-0.197c0.054,0.431,0.215,0.772,0.483,1.023c0.268,0.252,0.576,0.378,0.925,0.378
												c0.399,0,0.737-0.162,1.014-0.486c0.277-0.324,0.415-0.813,0.415-1.466c0-0.612-0.137-1.071-0.412-1.378
												c-0.274-0.306-0.631-0.459-1.071-0.459c-0.549,0-1.041,0.242-1.476,0.728l-1.551-0.225l0.98-5.19h5.054v1.789h-3.605
												l-0.299,1.694c0.426-0.213,0.862-0.319,1.306-0.319c0.848,0,1.567,0.308,2.156,0.925s0.884,1.417,0.884,2.401
												c0,0.82-0.238,1.553-0.714,2.197c-0.649,0.88-1.549,1.319-2.701,1.319c-0.921,0-1.671-0.247-2.252-0.741
												S360.943,50.183,360.83,49.349z"
                                ></path>
                                <path
                                  fill="#000000"
                                  className="noev"
                                  d="M358.007,103.746h-1.912v-7.204c-0.698,0.653-1.521,1.137-2.469,1.449v-1.734
												c0.499-0.164,1.041-0.473,1.626-0.929c0.585-0.456,0.986-0.987,1.204-1.596h1.551V103.746z"
                                ></path>
                              </g>
                              <g fill="#000000">
                                <path
                                  className="noev"
                                  fill="#000000"
                                  d="M706.881,79.787v-2.028h7.471v1.586c-0.616,0.606-1.243,1.477-1.881,2.611
												c-0.638,1.136-1.123,2.342-1.457,3.619c-0.335,1.278-0.5,2.419-0.494,3.425h-2.107c0.037-1.575,0.361-3.183,0.977-4.82
												c0.613-1.638,1.434-3.102,2.46-4.393H706.881z"
                                ></path>
                                <path
                                  className="noev"
                                  fill="#000000"
                                  d="M714.568,35.404l-2.115,0.233c-0.052-0.436-0.187-0.757-0.404-0.964s-0.5-0.311-0.848-0.311
												c-0.461,0-0.852,0.207-1.17,0.621c-0.318,0.415-0.52,1.278-0.603,2.589c0.544-0.643,1.221-0.964,2.029-0.964
												c0.912,0,1.693,0.348,2.344,1.042s0.976,1.591,0.976,2.689c0,1.167-0.342,2.103-1.025,2.807
												c-0.685,0.705-1.563,1.058-2.637,1.058c-1.15,0-2.096-0.447-2.837-1.341c-0.741-0.895-1.112-2.359-1.112-4.396
												c0-2.089,0.387-3.595,1.159-4.517c0.772-0.923,1.774-1.384,3.009-1.384c0.865,0,1.582,0.242,2.149,0.727
												S714.412,34.482,714.568,35.404z M709.615,40.17c0,0.71,0.164,1.259,0.49,1.645s0.699,0.579,1.119,0.579
												c0.404,0,0.741-0.158,1.011-0.474c0.27-0.316,0.404-0.835,0.404-1.556c0-0.741-0.146-1.283-0.435-1.628
												c-0.291-0.345-0.654-0.518-1.09-0.518c-0.419,0-0.773,0.165-1.064,0.494C709.761,39.042,709.615,39.527,709.615,40.17z"
                                ></path>
                                <path
                                  className="noev"
                                  fill="#000000"
                                  d="M756.504,45.833l2.176-0.226c0.063,0.492,0.246,0.882,0.553,1.17c0.306,0.288,0.658,0.432,1.057,0.432
												c0.457,0,0.843-0.186,1.158-0.556c0.316-0.371,0.475-0.93,0.475-1.676c0-0.699-0.156-1.225-0.471-1.574
												c-0.313-0.35-0.721-0.524-1.225-0.524c-0.627,0-1.189,0.277-1.687,0.831l-1.772-0.256l1.119-5.932h5.777v2.044h-4.121
												l-0.342,1.937c0.486-0.244,0.984-0.366,1.492-0.366c0.969,0,1.791,0.353,2.465,1.058s1.011,1.62,1.011,2.744
												c0,0.938-0.272,1.775-0.816,2.512c-0.741,1.005-1.771,1.508-3.087,1.508c-1.052,0-1.91-0.282-2.572-0.848
												C757.029,47.546,756.633,46.786,756.504,45.833z"
                                ></path>
                                <path
                                  className="noev"
                                  fill="#000000"
                                  d="M659.91,45.826l2.177-0.226c0.062,0.492,0.246,0.882,0.552,1.17c0.307,0.288,0.658,0.432,1.058,0.432
												c0.456,0,0.843-0.186,1.158-0.556c0.315-0.371,0.474-0.93,0.474-1.676c0-0.699-0.156-1.225-0.47-1.574s-0.722-0.524-1.225-0.524
												c-0.627,0-1.189,0.277-1.687,0.831l-1.773-0.256l1.12-5.932h5.776v2.044h-4.121l-0.342,1.937
												c0.487-0.244,0.984-0.366,1.493-0.366c0.969,0,1.79,0.353,2.464,1.058s1.012,1.62,1.012,2.744c0,0.938-0.272,1.775-0.816,2.512
												c-0.742,1.005-1.771,1.508-3.087,1.508c-1.052,0-1.91-0.282-2.573-0.848C660.436,47.539,660.039,46.779,659.91,45.826z"
                                ></path>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                    <button
                      className="finalSubmit"
                      onClick={submit}
                      style={{
                        cursor: data.length <= 0 && "no-drop",
                        backgroundColor:
                          data.length <= 0 ? "darkgray" : "#cbae18",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        <Spin spinning={spinning} fullscreen />
        <Modal
          title=""
          centered
          open={modalOpen}
          closeIcon={false}
          footer={[<></>]}
        >
          <div
            style={{
              color: "green",
              minHeight: 165,
              fontSize: 22,
              display: "flex",
              alignItems: "center",
            }}
          >
            Thank you. Your hair transplant cost analysis report will be sent to
            your mobile number soon!
          </div>
        </Modal>
      </div>
    </>
  );
}

export default Haircal;
